(function () {
    'use strict';

    angular.module('adminApp')
        .factory('DeleteAdminFeatureModal', DeleteAdminFeatureModal);

    DeleteAdminFeatureModal.$inject = ['$uibModal'];

    function DeleteAdminFeatureModal($uibModal) {
        function open(featureModel) {
            var modal = $uibModal.open({
                size: "md",
                resolve: {
                    featureModel: function () {
                        return featureModel;
                    }
                },
                templateUrl: "/static/templates/admin/modals/DeleteAdminFeatureModal.html",
                controller: DeleteAdminFeatureModalController
            });

            return modal.result;
        }

        return {
            open: open
        };
    }

    DeleteAdminFeatureModalController.$inject = ['$scope', '$uibModalInstance', 'Notification', 'aerosAdminApi',
        'featureModel'];

    function DeleteAdminFeatureModalController($scope, $uibModalInstance, Notification, aerosAdminApi, featureModel) {

        angular.extend($scope, {
            deleteFeature: deleteFeature
        });

        (function init() {
            $scope.featureModel = featureModel;
        })();


        function deleteFeature() {
            aerosAdminApi.featureApi.delete($scope.featureModel).then(function (results) {
                $uibModalInstance.close($scope.featureModel);
                Notification.success('Feature successfully deleted.');
            }, function (error) {
                Notification.error('Feature failed to delete. ' + error.data.message);
            });
        }

    }
})();